<template>
   <v-layout align-start>
      <v-flex>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG INFO PAGOS -->
         <DialogInfoPagos v-model="showDialogInfoPagos" :datos="infoPago" />

         <!-- DIALOG LOADING ESPERA DE GENERACION DE DOCUMENTO -->
         <v-dialog v-model="dialogEsperaReporte" persistent width="333">
            <v-card :color="dialogEsperaColor" dark class="pt-5 pb-2">
               <v-card-text style="color: white" class="text-justify">
                  <strong>{{ dialogEsperaMessage }}</strong>
                  <br /><br />
                  {{ dialogEsperaSubMessage }}

                  <div
                     v-if="
                        dialogEsperaMessage ==
                        'Esperando respuesta de ventana de pago.'
                     "
                     class="text-center"
                  >
                     <a
                        style="
                           color: white;
                           font-weight: bold;
                           font-size: 17px;
                           text-decoration: underline;
                        "
                        @click="refreshPage"
                     >
                        <br />
                        Cancelar transacción
                        <br /><br />
                     </a>
                  </div>

                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>

         <v-dialog v-model="dialogReportes" persistent max-width="400px">
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md fluid>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">
                                 Reportes de pagos
                              </span>
                           </v-flex>
                           <v-flex xs12>
                              <v-expansion-panels>
                                 <v-expansion-panel>
                                    <v-expansion-panel-header>
                                       <div>
                                          <v-icon left>fas fa-sliders-h</v-icon>
                                          Opciones
                                       </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                       <v-layout wrap>
                                          <v-flex xs12>
                                             <span
                                                class="text-subtitle-1 font-weight-medium"
                                                >Rango de fechas</span
                                             >
                                          </v-flex>
                                          <v-flex xs12 sm6>
                                             <CustomDatePicker
                                                :date="fechaInicio"
                                                label="Fecha inicio"
                                                min="2015-01"
                                                txt-style="width: 150px;"
                                                @updateFecha="
                                                   fechaInicio = $event
                                                "
                                             />
                                          </v-flex>
                                          <v-flex xs12 sm6>
                                             <CustomDatePicker
                                                :date="fechaFin"
                                                label="Fecha fin"
                                                min="2015-01"
                                                txt-style="width: 150px;"
                                                @updateFecha="fechaFin = $event"
                                             />
                                          </v-flex>
                                          <v-flex xs12>
                                             <span
                                                class="text-subtitle-1 font-weight-medium"
                                                >Orientación de página</span
                                             >
                                          </v-flex>
                                          <v-flex xs12>
                                             <div class="layout justify-center">
                                                <v-radio-group
                                                   v-model="checkOrientacion"
                                                   class="justify-center"
                                                   row
                                                >
                                                   <v-radio
                                                      color="primary"
                                                      label="Horizontal"
                                                      value="landscape"
                                                   ></v-radio>
                                                   <v-radio
                                                      color="primary"
                                                      label="Vertical"
                                                      value="portrait"
                                                   ></v-radio>
                                                </v-radio-group>
                                             </div>
                                          </v-flex>
                                       </v-layout>
                                    </v-expansion-panel-content>
                                 </v-expansion-panel>
                              </v-expansion-panels>
                           </v-flex>
                           <v-flex xs12 mt-1>
                              <v-btn
                                 color="red darken-3 white--text"
                                 block
                                 @click="reporteLibreriaLicencias"
                              >
                                 <v-icon left>fa-file-pdf</v-icon>
                                 PDF
                              </v-btn>
                           </v-flex>
                           <v-flex xs12 mt-2>
                              <v-btn
                                 color="green darken-3 white--text"
                                 block
                                 @click="reporteExcel"
                              >
                                 <v-icon left>fa-file-excel</v-icon>
                                 excel
                              </v-btn>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        text
                        @click.native="dialogReportes = false"
                        >Salir</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- TOOLBAR -->
         <v-toolbar flat color="white" class="pt-3">
            <v-toolbar-title style="font-weight: 500">
               Pagos de librería
            </v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>

            <div class="mt-6 ml-2">
               <CustomDatePicker
                  :date="fecha"
                  label="Fecha"
                  min="2015-01"
                  txt-style="width: 150px;"
                  @updateFecha="fecha = $event"
               />
            </div>
            <v-spacer></v-spacer>
            <v-text-field
               v-if="verNuevo == 0"
               ref="txtBusqueda"
               v-model="searchComputed"
               append-icon="fa-search"
               class="mt-6"
               color="primary"
               label="Busqueda"
               single-line
               hide-deatails
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn class="grey lighten-3" @click="dialogReportes = true"
               >Reportes
               <v-icon color="grey darken-2" right>fa-print</v-icon></v-btn
            >
         </v-toolbar>

         <v-layout wrap>
            <v-flex xs12>
               <!-- DATATABLE Pago -->
               <v-data-table
                  v-if="verNuevo == 0"
                  :headers="headers"
                  :items="filteredPagos"
                  :loading="loadingPagos"
                  loading-text="Listando pagos... Por favor espere"
                  :search="search"
                  fixed-header
                  :footer-props="{
                     itemsPerPageOptions: [25, 50],
                     itemsPerPageText: 'Filas por página:'
                  }"
                  class="elevation-0"
               >
                  <!-- Filtros de busqueda -->
                  <template #footer="{}">
                     <tr>
                        <td :colspan="headers.length">
                           <v-layout align-center justify-start fill-height>
                              <div style="margin-right: 20px">
                                 <span class="text-body-1 font-weight-medium">
                                    Buscar por
                                 </span>
                              </div>
                              <div style="margin-top: 5px">
                                 <v-radio-group
                                    v-model="radiosBusqueda"
                                    row
                                    @change="focusBusqueda"
                                 >
                                    <v-radio
                                       label="Carnet"
                                       color="primary"
                                       value="r-carnet"
                                    ></v-radio>
                                    <v-radio
                                       label="Npe"
                                       color="primary"
                                       value="r-npe"
                                    ></v-radio>
                                    <v-radio
                                       label="Arancel"
                                       color="primary"
                                       value="r-arancel"
                                    ></v-radio>
                                    <v-radio
                                       label="N° Referencia"
                                       color="primary"
                                       value="r-referencia"
                                    ></v-radio>
                                    <v-radio
                                       label="N° Autorización"
                                       color="primary"
                                       value="r-autorizacion"
                                    ></v-radio>
                                    <v-radio
                                       label="Fecha Pago"
                                       color="primary"
                                       value="r-fecha"
                                    ></v-radio>
                                 </v-radio-group>
                              </div>
                           </v-layout>
                        </td>
                     </tr>
                  </template>

                  <template #item.alumnoCarnet="{ item }">
                     <a class="font-weight-medium" @click="setInfoPago(item)">
                        {{ item.alumnoCarnet }}
                     </a>
                  </template>

                  <template #item.monto="{ item }">
                     $
                     {{ numFormatIntl(Number(item.monto)) }}
                  </template>

                  <template #item.comprobantePath="{ item }">
                     <v-tooltip
                        v-if="item.comprobantePath != '' && item.esBeca"
                        top
                        max-width="300"
                        color="primary"
                     >
                        <template #activator="{ on }">
                           <v-btn
                              text
                              small
                              fab
                              color="primary"
                              v-on="on"
                              @click="descargarAdjunto(item)"
                           >
                              <v-icon>fas fa-download</v-icon>
                           </v-btn>
                        </template>
                        <span style="font-size: 15px">
                           Descargar archivo enviado
                        </span>
                     </v-tooltip>
                     <div v-if="item.comprobantePath == '' && item.esBeca">
                        Pendiente
                     </div>
                     <div v-if="!item.esBeca">NA</div>
                  </template>

                  <template #item.esBeca="{ item }">
                     <v-tooltip
                        v-if="item.esBeca"
                        top
                        max-width="300"
                        color="primary"
                     >
                        <template #activator="{ on }">
                           <v-icon color="primary" v-on="on">
                              fas fa-graduation-cap
                           </v-icon>
                        </template>
                        <span style="font-size: 15px"> Pago con beca </span>
                     </v-tooltip>
                     <div v-else>No</div>
                  </template>

                  <!-- Si no hay datos, mostrar boton para listar de nuevo -->
                  <template slot="no-data">
                     <div v-if="pagos.length == 0">
                        <v-container>
                           <v-layout wrap align-center>
                              <v-flex xs12 class="text-center">
                                 <v-btn color="primary" @click="reset"
                                    >Sin pagos / recargar</v-btn
                                 >
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </div>
                     <div v-if="filteredPagos.length == 0 && pagos != 0">
                        <v-alert
                           dark
                           class="mt-4"
                           color="primary"
                           icon="fa-exclamation-circle"
                        >
                           La busquedad de "{{ search }}" no devolvió ningun
                           resultado.
                        </v-alert>
                     </div>
                  </template>
               </v-data-table>
            </v-flex>
         </v-layout>
      </v-flex>
   </v-layout>
</template>

<script>
import XLSX from 'xlsx'
import axios from 'axios'
import debounce from 'lodash/debounce'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'
const DialogInfoPagos = () => import('./custom/DialogInfoPagos')
const CustomDatePicker = () => import('./custom/CustomDatePicker')
const getReporteLib = () => import('../mixins/reporteLib')

export default {
   name: 'PagosLibreria',
   components: {
      CustomDatePicker,
      DialogInfoPagos
   },
   mixins: [formatters],
   data: (vm) => ({
      // Variables
      dialogEditar: false,
      dialogReportes: false,
      editedIndex: -1,
      headers: [
         {
            text: 'N°',
            value: 'correlativo',
            class: 'titulo'
         },
         {
            text: 'Carnet',
            value: 'alumnoCarnet',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Fecha Pago',
            value: 'fecha',
            class: 'titulo'
         },
         {
            text: 'Concepto',
            value: 'concepto',
            class: 'titulo'
         },
         {
            text: 'NPE',
            value: 'npe',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Referencia',
            value: 'referencia',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Autorizacion',
            value: 'autorizacion',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Monto',
            value: 'monto',
            align: 'center',
            class: 'titulo'
         }
      ],
      // Variables de formularios
      btnDetalleColor: 'primary white--text',
      checkOrientacion: 'landscape',
      detalleIcon: 'fa-plus',
      dialogEsperaColor: 'primary',
      dialogEsperaMessage: 'Generando reporte...',
      dialogEsperaReporte: false,
      dialogEsperaSubMessage: 'No cierre esta pestaña o el navegador.',
      fecha: vm.toISOLocal(new Date()).substr(0, 10),
      fechaFin: vm.toISOLocal(new Date()).substr(0, 10),
      fechaInicio: vm.toISOLocal(new Date()).substr(0, 10),
      pagos: [],
      radiosBusqueda: 'r-npe',
      search: '',
      talonario: [],
      showDialogInfoPagos: false,
      infoPago: {},

      // Otros
      read: 0,
      result: [],
      snackColor: 'primary',
      snackText: '',
      snackbar: false,
      valida: 0,
      verNuevo: 0,
      loadingPagos: false
   }),
   computed: {
      ...authComputed,
      countCouta() {
         if (this.pagos.length > 0) {
            return this.pagos.filter(
               (obj) => obj.numeroTalonario != null && !obj.esBeca
            ).length
         } else {
            return 0
         }
      },
      countCoutaBeca() {
         if (this.pagos.length > 0) {
            return this.pagos.filter(
               (obj) => obj.numeroTalonario != null && obj.esBeca
            ).length
         } else {
            return 0
         }
      },
      countArancelPrivado() {
         if (this.pagos.length > 0) {
            return this.pagos.filter(
               (obj) => obj.numeroTalonario == null && !obj.clienteExterno
            ).length
         } else {
            return 0
         }
      },
      countArancelPublico() {
         if (this.pagos.length > 0) {
            return this.pagos.filter(
               (obj) => obj.numeroTalonario == null && obj.clienteExterno
            ).length
         } else {
            return 0
         }
      },
      // Filtro de busqueda de datatable
      filteredPagos() {
         switch (this.radiosBusqueda) {
            case 'r-npe':
               return this.pagos.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.npe)
                  )
               })
               break
            case 'r-carnet':
               return this.pagos.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.alumnoCarnet)
                  )
               })
               break
            case 'r-arancel':
               return this.pagos.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.arancel)
                  )
               })
               break
            case 'r-referencia':
               return this.pagos.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.referencia)
                  )
               })
               break
            case 'r-autorizacion':
               return this.pagos.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.autorizacion)
                  )
               })
               break
            case 'r-fecha':
               return this.pagos.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.fecha)
                  )
               })
               break
            default:
               return this.pagos
         }
      },
      searchComputed: {
         get() {
            return this.search
         },
         set: debounce(function (newVal) {
            this.search = newVal.trim()
         }, 500)
      }
   },
   watch: {
      fecha(val) {
         this.pagos = []
         this.listarPagos()
         this.fechaInicio = val
         this.fechaFin = val
      }
   },
   created() {
      // Validando acceso al componente
      if (this.permController(7, 'ver')) {
         this.listarPagos()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      setInfoPago(item) {
         this.infoPago = item
         this.showDialogInfoPagos = true
      },
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Si no cargaron bien las pagos en el datatable vuelve a listar
      reset() {
         this.listarPagos()
      },
      // ------------------ LISTAR INFORMACION --------------------
      /* Metodos para listar informacion desde la API */

      // Listar las pagos de un periodo y popula el datatable principal
      listarPagos() {
         this.loadingPagos = true

         axios
            .get(
               'api/Pago?Year=' +
                  this.fecha.substr(0, 4) +
                  '&Month=' +
                  this.fecha.substr(5, 2) +
                  '&Day=' +
                  this.fecha.substr(8, 2) +
                  '&responseCode=00&Ebooks=true'
            )
            .then((response) => {
               this.pagos = response.data
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               this.loadingPagos = false
            })
      },
      reporteLibreriaLicencias() {
         var result = []
         this.dialogEsperaReporte = true
         axios
            .get(
               'api/Pago?FechaInicio=' +
                  this.fechaInicio +
                  '&FechaFin=' +
                  this.fechaFin +
                  '&responseCode=00&Ebooks=true'
            )
            .then((response) => {
               result = response.data
            })
            .catch((error) => {
               console.log(error)
               this.dialogEsperaReporte = false
            })
            .finally(() => {
               this.imprimirReporteLicencias(
                  result,
                  'PAGOS DE LICENCIAS / EBOOKS',
                  this.$vuetify.breakpoint.smAndUp ? 'open' : 'save'
               )
            })
      },
      imprimirReporteLicencias(array, titulo, mode) {
         var totalFinal = 0

         const buildTablePagos = (pagos) => {
            var rowTemp = []

            // ENCABEZADOS
            rowTemp.push([
               {
                  text: 'N°',
                  alignment: 'center',
                  bold: true
               },
               {
                  text: 'Carnet',
                  alignment: 'center',
                  bold: true
               },
               {
                  text: 'DUI',
                  alignment: 'center',
                  bold: true
               },
               { text: 'Nombre', alignment: 'center', bold: true },
               { text: 'Concepto', alignment: 'center', bold: true },
               { text: 'Fecha de pago', alignment: 'center', bold: true },
               { text: 'Referencia', alignment: 'center', bold: true },
               { text: 'Autorización', alignment: 'center', bold: true },
               { text: 'Monto', alignment: 'center', bold: true }
            ])

            // DETALLES
            pagos.forEach((m) => {
               totalFinal = Number(totalFinal) + Number(m.monto)
               rowTemp.push([
                  { text: m.correlativo, alignment: 'center' },
                  { text: m.alumnoCarnet, alignment: 'center', noWrap: true },
                  { text: m.dui, alignment: 'center', noWrap: true },
                  { text: m.nombre, alignment: 'left' },
                  { text: m.arancel, alignment: 'left' },
                  { text: m.fecha, alignment: 'center' },
                  { text: m.referencia, alignment: 'center' },
                  { text: m.autorizacion, alignment: 'center' },
                  {
                     table: {
                        widths: [20, '*'],
                        body: [
                           [
                              { text: '$', alignment: 'center' },
                              {
                                 text: this.numFormatIntl(m.monto),
                                 alignment: 'right'
                              }
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     margin: [0, 0, 2, 0]
                  }
               ])
            })

            // Agregando row de total
            rowTemp.push([
               {
                  text: 'TOTAL',
                  alignment: 'right',
                  bold: true,
                  colSpan: 6
               },
               {},
               {},
               {},
               {},
               {},
               {
                  table: {
                     widths: [20, '*'],
                     body: [
                        [
                           { text: '$', alignment: 'center' },
                           {
                              text: this.numFormatIntl(totalFinal),
                              alignment: 'right'
                           }
                        ]
                     ]
                  },
                  layout: 'noBorders',
                  margin: [0, 0, 2, 0],
                  colSpan: 3
               },
               {},
               {}
            ])

            return rowTemp
         }

         var dd = {
            // Tamaño de pagina
            pageSize: 'LETTER',
            pageOrientation: this.checkOrientacion,
            pageMargins: [20, 20],
            // Pie de pagina con numero de páginas
            footer: (currentPage, pageCount) => {
               return {
                  margin: [20, 0],
                  fontSize: 10,
                  alignment: 'right',
                  text:
                     'Generado: ' +
                     new Date().toLocaleString('es-ES', {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: 'true'
                     }) +
                     ' - Página ' +
                     currentPage.toString() +
                     ' de ' +
                     pageCount
               }
            },
            content: [
               {
                  text:
                     '\nREPORTE DE ' +
                     titulo +
                     ' ' +
                     this.formatDate(this.fecha) +
                     (this.fechaInicio != this.fechaFin
                        ? ' al ' + this.formatDate(this.fechaFin)
                        : '') +
                     ' - COLECTURIA DIGITAL\n\n',
                  bold: true,
                  alignment: 'center'
               },
               {
                  table: {
                     headerRows: 1,
                     dontBreakRows: true,
                     widths: [
                        'auto',
                        'auto',
                        'auto',
                        '*',
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                        'auto'
                     ],
                     body: buildTablePagos(array)
                  },
                  layout: '',
                  margin: [this.checkOrientacion == 'portrait' ? 0 : 10, 0]
               }
            ],
            defaultStyle: {
               fontSize: this.checkOrientacion == 'portrait' ? 8 : 9,
               font: 'TimesNewRoman'
            }
         }

         var filename = titulo + ' - ' + this.fecha + '.pdf'
         getReporteLib().then((res) => res.generarPDF(mode, dd, filename))
         this.dialogEsperaReporte = false
      },
      reporteExcel() {
         var resPago = []

         axios
            .get(
               'api/Pago?FechaInicio=' +
                  this.fechaInicio +
                  '&FechaFin=' +
                  this.fechaFin +
                  '&responseCode=00&Ebooks=true'
            )
            .then((response) => {
               resPago = response.data.map((obj) => {
                  return {
                     pagoId: obj.pagoId,
                     correlativo: obj.correlativo,
                     alumnoCarnet: obj.alumnoCarnet,
                     dui: obj.dui,
                     nombre: obj.nombre,
                     fecha: obj.fecha,
                     fechaPagare: obj.fechaPagare,
                     concepto: obj.concepto,
                     npe: obj.npe,
                     referencia: obj.referencia,
                     autorizacion: obj.autorizacion,
                     monto: obj.monto,
                     montoOriginal: obj.montoOriginal,
                     procesado: obj.procesado,
                     arancelCodigo: obj.arancelCodigo,
                     arancel: obj.arancel,
                     responseCode: obj.responseCode,
                     numeroTalonario: obj.numeroTalonario,
                     numeroCuota: obj.numeroCuota,
                     tieneBeca: obj.esBeca,
                     tipoBeca: obj.tipoBeca,
                     categoria: obj.categoria,
                     porcentaje: obj.porcentaje,
                     esMaestria: obj.esMaestria
                  }
               })
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               // Generar excel
               var heading = [
                  [
                     'Pago ID',
                     'Correlativo',
                     'AlumnoCarnet',
                     'DUI',
                     'Nombre',
                     'Fecha',
                     'FechaPagare',
                     'Concepto',
                     'Npe',
                     'Referencia',
                     'Autorizacion',
                     'Monto',
                     'MontoOriginal',
                     'Procesado',
                     'ArancelCodigo',
                     'Arancel',
                     'ResponseCode',
                     'NumeroTalonario',
                     'NumeroCuota',
                     'TieneBeca',
                     'TipoBeca',
                     'CategoriaBeca',
                     'PorcentajeBeca',
                     'EsMaestria'
                  ]
               ]

               var filename =
                  'Pagos Diarios Libreria' + this.fecha + ' Reporte.xlsx'
               var wb = XLSX.utils.book_new()

               // Agregando headers y data de arreglo
               var ws = XLSX.utils.aoa_to_sheet(heading)
               XLSX.utils.sheet_add_json(ws, resPago, {
                  skipHeader: true,
                  origin: 'A2'
               })

               // Asignando ancho de columnas segun contenido
               this.autofitColumns(resPago, ws)

               // Agregando hoja y exportando archivo
               XLSX.utils.book_append_sheet(wb, ws, 'Reporte')
               XLSX.writeFile(wb, filename)
            })
      }
   }
}
</script>
